import React, { Component, Fragment, createRef } from 'react';
import {
    Image,
    Menu,
    Icon,
} from 'semantic-ui-react';
import sizeMe from 'react-sizeme'
import StackGrid, {transitions} from 'react-stack-grid';
import Lightbox from 'react-images';

const { scaleDown } = transitions;

function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

const gallery_data = {
    'lara': ['Lara Croft', 13],
    'art': ['Arts', 14],
    'sketches': ['Sketches', 5],
};

const galleryImgs = (type) => {
    let images = [];
    for(let i=1; i<=gallery_data[type][1]; i++)
        images.push({thumbnail: `/img/tumb/${type}/${i}.jpeg`, src: `/img/${type}/${i}.jpeg`, original: `/img/original/${type}/${i}.jpeg`});
    return images;
}
    

class Gallery extends Component {
    constructor(props) {
        super(props);
        let pictures = [];
        Object.keys(gallery_data).forEach((f) => pictures = pictures.concat(galleryImgs(f)));

        this.state = {
            images: pictures.reverse(),
            activeItem: 'all',
            open: false,
            curimage: 0,
        };
    }

    componentDidMount() {
    }

    contextRef = createRef();

    changeActive = (type) => {
        let pictures = [];
        if(type === 'all'){
            Object.keys(gallery_data).forEach((f) => pictures = pictures.concat(galleryImgs(f)));
        }else{
            pictures = galleryImgs(type);
        }

        this.setState({activeItem: type, images: pictures.reverse()});
    }

    render() {
        return (
            <Fragment>
                <Menu inverted secondary>
                    <Menu.Item active={this.state.activeItem === 'all'} key='all' name='All' onClick={() => this.changeActive('all')} />
                    {Object.keys(gallery_data).map((f) => <Menu.Item active={this.state.activeItem === f} key={f} name={gallery_data[f][0]} onClick={() => this.changeActive(f)} />)}
                </Menu>
                <Lightbox
                    images={this.state.images}
                    isOpen={this.state.open}
                    currentImage={this.state.curimage}
                    onClickPrev={() => this.setState({curimage: this.state.curimage - 1})}
                    onClickNext={() => this.setState({curimage: this.state.curimage + 1})}
                    onClose={() => this.setState({open: false})}
                    onClickThumbnail={ (img) => this.setState({curimage: img})}
                    showThumbnails
                    customControls={[<a href={this.state.images[this.state.curimage].original} target="_blank" rel="noopener noreferrer" download={'winecrow.ru.'+this.state.activeItem+'-'+this.state.curimage+'.jpeg'} ><Icon name='save' inverted size='large' style={{padding: '10px'}} /></a>]}
                />
                <StackGrid
                    columnWidth={ this.props.size.width <=768 ? '100%' : '33.33%' }
                    appear={scaleDown.appear}
                    appeared={scaleDown.appeared}
                    enter={scaleDown.enter}
                    entered={scaleDown.entered}
                    leaved={scaleDown.leaved}
                    gutterWidth={5}
                    gutterHeight={5}
                    monitorImagesLoaded={true}
                    gridRef={grid => this.grid = grid}
                >
                    {
                        this.state.images.map((f,index) =>
                                <Image
                                    key={this.state.activeItem + index}
                                    src={f.thumbnail} 
                                    style={{width:'100%'}}
                                    onClick={() => this.setState({curimage: index, open: true})}
                                    label={{ as: 'a', color: 'black', corner: 'right', icon: 'save', href: f.original, target: '_blank', rel: "noopener noreferrer", download: 'winecrow.ru.'+this.state.activeItem+'-'+this.state.curimage+'.jpeg' }}
                                />
                            )
                    }
                </StackGrid>
            </Fragment>
        );
    }
}

export default sizeMe()(Gallery);
