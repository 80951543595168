import React, { Component, Fragment } from 'react';
import {
    Menu,
} from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';

const comic_data = {
     'lara_witch_comic': ['Lara Witch',5],
     'l4dcomic': ['L4D Comic',19],
     'l4dcomic_ru': ['L4D Comic [RU]',19],
}

const galleryImgs = (type) => {
    let images = [];
    for(let i=1; i<=comic_data[type][1]; i++)
        images.push({thumbnail: `/img/tumb/${type}/${i}.jpeg`, original: `/img/${type}/${i}.jpeg`});
    return images;
}
    
export default class Comic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: galleryImgs('l4dcomic'),
            activeItem: 'l4dcomic',
        };
    }

    changeActive = (type) => {
        let pictures = [];
        pictures = galleryImgs(type);

        this.setState({activeItem: type, images: pictures}, () => this.ig.slideToIndex(0));
    }

    render() {
        return (
            <Fragment>
                <Menu inverted secondary size='small'>
                    {Object.keys(comic_data).map((f) => <Menu.Item active={this.state.activeItem === f} key={f} name={comic_data[f][0]} onClick={() => this.changeActive(f)} />)}
                </Menu>
                <ImageGallery
                    slideDuration={0}
                    items={this.state.images}
                    useBrowserFullscreen={true}
                    showThumbnails={false}
                    lazyLoad
                    infinite={false}
                    showPlayButton={false}
                    ref={(node) => this.ig = node}
                />
            </Fragment>
        )
    }
}

