import React, { Component, Fragment } from 'react';
import {
    Image,
    Card,
    List,
    Icon,
    Transition,
} from 'semantic-ui-react';
import { buildUrl } from 'react-instafeed';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
const tumblr = require('tumblr.js');
const client = tumblr.createClient({
    consumer_key: 'uNcmq4r4yF6B6jv5qOZnsHL1o8dC51FUytulZnMnlrjcA4DSJg',
    token: '5f0hMhbaOQFaKm5pH4EVH0C0NPOfRkNP0gR0mhRi1mss46HWqX',
});

const options = {
    accessToken: '7183957143.aa70d06.79172c969970466c9838288143aeb51e',
    clientId: 'aa70d068bd254c6dae5d4fe5ccb124da',
    get: 'user', // popular, user
    locationId: null,
    resolution: 'standard_resolution', // thumbnail, low_resolution, standard_resolution
    sortBy: 'most-recent', // none, least-commented, least-liked, least-recent, most-commented, most-liked, most-recent, random
    tagName: null,
    userId: 7183957143,
}

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curimg: 0,
        };
    }


    render() {
        return (
            <div className='Alexslidercontainer'>
                <Image src={this.props.images[this.state.curimg].images[options.resolution].url} />
                {
                    this.state.curimg !== 0 &&
                        <Icon name='angle left' onClick={() => this.setState({curimg: this.state.curimg -1})} className='Alexslider left' size='huge'  />
                }
                {
                    this.state.curimg !== this.props.images.length-1 &&
                        <Icon name='angle right'  onClick={() => this.setState({curimg: this.state.curimg +1})} className='Alexslider right'  size='huge'  />
                }
            </div>
        )
    }
}

export default class Instafeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            insta_data: [],
            tumblr_data: [],
            loading: 0,
        };
    }

    async componentDidMount() {
        //console.log('url',buildUrl(options));
	/* no insta
        axios.get(buildUrl(options))
            .then(res => {
                //console.log('data',res.data);
                this.setState({insta_data: res.data.data, loading: this.state.loading+1});
            });
	    */
	    this.setState({loading: this.state.loading+1});
        /*
        client.blogPosts('winecrow', {type: 'photo' }, (err, resp) => {
            //console.log(resp.posts); // use them for something
            this.setState({tumblr_data: resp.posts, loading: this.state.loading+1});
        });
        */
    }

    render() {
        // const {insta_data, tumblr_data} = this.state;
        /*
        let data = [];
        insta_data &&
            insta_data.map(({ caption, id, images, tags, created_time, likes, link, carousel_media, videos }, index) => {
                const image = images[options.resolution];
                data.push({
                    insta: true,
                    tumblr: false,
                    url: image.url,
                    description: !!caption ? caption.text : '',
                    likes: likes.count,
                    date: new Date(created_time*1000),
                    link: link,
                    carousel_media: carousel_media,
                    videos: !!videos ? videos[options.resolution] : null,
                });
                return null;
            });
        tumblr_data && tumblr_data.map(({ photos, post_url, timestamp, caption, note_count }, index) => {
            photos.map((f) => {
                data.push({
                    insta: false,
                    tumblr: true,
                    url: f.original_size.url,
                    description: caption,
                    likes: note_count,
                    date: new Date(timestamp*1000),
                    link: post_url,
                });
                return null;
            }
            );
            return null;
        }
        );
        */

        return (
            <Fragment>
                {this.state.loading !== 2 &&
                        <Icon loading name='spinner' size='massive' color='grey' />
                        }
                        {/*
                <Transition.Group  animation='scale' duration={1000}>
                */}
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="winecrow"
theme="dark"
linkColor="#fff"
borderColor="#fff"
noHeader={true}
noFooter={true}
transparent={true}
onLoad={() => {
    this.setState({loading: this.state.loading+1});
    console.log("complete "+this.state.loading);
}}
/>
                    {// eslint-disable-next-line no-unused-vars
                        /*
                        this.state.loading === 2 &&
                        data.sort((a,b) => (a.date > b.date) ? -1 : ((b.date > a.date) ? 1 : 0))
                        .map(({ insta, tumblr, url, description, likes, date, link, carousel_media, videos }, index) => {
                            return (
                                <Card key={index} fluid style={{textAlign:'left'}} className='alexfeedcard' >
                                    {
                                        !!carousel_media ? <Slider images={carousel_media} /> : 
                                            !!videos ? <ReactPlayer url={videos.url} loop controls light={url} width='100%' playing /> :
                                            <Image src={url} />
                                    }
                                    {!!description && 
                                            <Card.Content description={description} />
                                    }
                                    <Card.Content extra>
                                        <List horizontal className="right floated">
                                            <List.Item>
                                                <List.Icon name='like' /> {likes}
                                            </List.Item>
                                            <List.Item >
                                                {date.toLocaleDateString()}
                                            </List.Item>
                                        </List>
                                        <List horizontal>
                                            <List.Item >
                                                <a href={!!videos ? videos.url : url}  rel="noopener noreferrer" download={'winecrow.ru.'+index} target='_blank' >
                                                    <List.Icon name='download' />
                                                </a>
                                            </List.Item>
                                            <List.Item as='a' href={link} target='_blank' >
                                                <List.Icon name={insta ? 'instagram' : 'tumblr'} />
                                            </List.Item>
                                        </List>
                                    </Card.Content>
                                </Card>
                            )
                        })
                        */
                    }
                                {/*
                </Transition.Group>
                */}
            </Fragment>
        )
    }

}

