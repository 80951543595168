import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import './App.css';
import {
    Grid,
    Header,
    Container,
    Menu,
    Icon,
    Image,
} from 'semantic-ui-react';
import Instafeed from './components/Instafeed.js';
import Gallery from './components/Gallery.js';
//import Avatar from './img/avatar.jpg';
import ScrollUpButton from 'react-scroll-up-button';
import Comic from './components/Comic.js';

const pages = {
    'Feed': '/',
    'Gallery': '/gallery',
    'Comics': '/comics',
};

const contacts = {
//    'instagram': {link:"https://www.instagram.com/winecroww/"},
    //'tumblr': {link: 'https://winecrow.tumblr.com'},
    //'deviantart': {link: 'https://www.deviantart.com/winecr0w'},
}

const Feed = (props) => {
    return (
        <Grid stackable style={{marginTop: '10px'}}>
            <Grid.Column width={3} textAlign='center'>
                <div className="leftMenu">
                    {/*
                    <Header as='h1' inverted >
                        WINECROW
                        <Header.Subheader >Wine + Pizza + Games + Drawing = Me</Header.Subheader>
                    </Header>
                    */}
                    <Menu inverted secondary size='small' vertical fluid>
                        {Object.keys(pages).map((f,i) => <Menu.Item key={i} name={f} as={Link} to={pages[f]} active={pages[f] === '/'} />)}
                    </Menu>
                </div>
            </Grid.Column>
            <Grid.Column width={10} textAlign='center'>
                <Image
                src="/img/sign.png"
                style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '25px'}}
            />
                <Instafeed />
            </Grid.Column>
            <Grid.Column width={3} textAlign='left'>
                <Menu icon vertical inverted secondary >
                    {Object.keys(contacts).map((f,i) =>
                        <Menu.Item key={i} name={f} as='a' href={contacts[f].link} target="_blank" >
                            <Icon name={f} />
                        </Menu.Item>
                    )}
                </Menu>
                <ScrollUpButton />
            </Grid.Column>
        </Grid>
    )
}

const TopMenu = (props) => (
    <Menu size="tiny" inverted attached='bottom'>
        <Container>
            {Object.keys(pages).map((f,i) => <Menu.Item key={i} name={f} as={Link} to={pages[f]} active={pages[f] === props.activeItem} />)}
            <Menu.Menu position="right">
                {Object.keys(contacts).map((f,i) =>
                    <Menu.Item key={i} name={f} as='a' href={contacts[f].link} >
                        <Icon name={f} />
                    </Menu.Item>
                )}
            </Menu.Menu>
        </Container>
    </Menu>
)

const GalleryPage = (props) => (
    <React.Fragment>
        <TopMenu activeItem='/gallery' />
        <Gallery />
        <ScrollUpButton />
    </React.Fragment>
);

const ComicPage = (props) => (
    <React.Fragment>
        <TopMenu activeItem='/comics' />
        <Comic />
    </React.Fragment>
);

class App extends Component {
    render() {
        return (
            <Container >
                <Switch>
                    <Route exact path='/' render={() => <Feed /> } /> 
                    <Route exact path='/gallery' render={() => <GalleryPage /> } />
                    <Route exact path='/comics' render={() => <ComicPage /> } />
                </Switch>
            </Container>
        );
    }
}

export default App;
